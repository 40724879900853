import React from 'react';
import {Hero} from "react-bulma-components";
import freeLesson from "../img/img-free-lesson-min.jpg";
import freeLessonMobile from "../img/img-free-lesson-mobile-min.jpg";
import {useTranslation} from "react-i18next";

const PageNotFound = () => {
    const {t} = useTranslation();
    return (
        <><Hero className="snap-point is-hidden-mobile" size="fullheight"
              style={{backgroundImage: `url(${freeLesson})`, backgroundSize: 'cover'}}>
            <Hero.Body style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end'}}>
                <h1 className="title page-title has-text-white is-spaced">404</h1>
                <h1 className="title page-title has-text-white is-spaced">{t('pageNotFound')}</h1>
            </Hero.Body>
        </Hero>
        <Hero className="is-hidden-tablet" size="large"
              style={{backgroundImage: `url(${freeLessonMobile})`, backgroundSize: 'cover'}}>
            <Hero.Body>
                <h1 className="title page-title has-text-white is-spaced">404</h1>
                <br/>
                <h1 className="title page-title has-text-white is-spaced">{t('pageNotFound')}</h1>
            </Hero.Body>
        </Hero>
        </>
    );
}

export default PageNotFound;
