import React from 'react';
import {Button, Columns, Hero, Icon} from "react-bulma-components";
import {useTranslation} from "react-i18next";
import ArrowLeft from "../icons/ArrowLeft";
import ImgBreakShape from "../icons/ImgBreakShape";
import ArrowRight from "../icons/ArrowRight";
import oneTennis from "../img/logo-one-tennis.jpg";

function Trainer({imgSrc, imgSrcMin, title, subtitle, description, slideNext, slidePrev, curr}) {
	const {t} = useTranslation();
	return (<>
		<Hero className="is-hidden-mobile" size="fullheight"
		      style={{backgroundImage: `url(${imgSrc})`, backgroundSize: 'cover'}}>
			<Hero.Body>
				<Columns style={{width: '100%'}}>
					<Columns.Column
						tablet={{
							size: 'one-third',
						}}
						desktop={{
							size: 'one-third',
						}}
						widescreen={{
							size: 'one-third',
						}}
						fullhd={{
							size: 'one-third',
						}}
						style={{margin: 'auto'}}
					>
						<div className="slideInfo">
							<Button className='is-text' onClick={slidePrev}>
								<Icon className="slideBtnIcon">
									<ArrowLeft width="22px" height="40px"/>
								</Icon>
							</Button>
							<span className="currSlide">0{curr}</span>
							<Icon className="">
								<ImgBreakShape height="90px"/>
							</Icon>
							<span className="totalSlides">07</span>
							<Button className='is-text' onClick={slideNext}>
								<Icon className="slideBtnIcon">
									<ArrowRight width="22px" height="40px"/>
								</Icon>
							</Button>
						</div>
					</Columns.Column>
					<Columns.Column
						desktop={{
							size: 'one-third',
						}}
						widescreen={{
							size: 'one-third',
						}}
						fullhd={{
							size: 'one-third',
						}}
					>
					</Columns.Column>
					<Columns.Column
						tablet={{
							size: 'two-thirds',
						}}
						desktop={{
							size: 'one-third',
						}}
						widescreen={{
							size: 'one-third',
						}}
						fullhd={{
							size: 'one-third',
						}}
						className="has-text-left padded-right"
					>

						{curr > 1 &&
						<h1 className="title trainers-headline has-text-white is-spaced">{t("about.trainers")}</h1>}
						<h1 className="title page-title has-text-white is-spaced is-uppercase">{t(title)}</h1>
						<h2 className="subtitle page-description has-text-white">{t(subtitle)}</h2>
						<h2 className="subtitle page-description has-text-white">{t(description)}</h2>
						{curr === 1 && (<>
							<a href="https://onetennis.ro/" target="_blank">
								<img className="oneTennisImg" src={oneTennis}/>
							</a>
							<div className="subtitle page-description has-text-white">{t('footer.oneTennis')}</div>
						</>)}
					</Columns.Column>
				</Columns>
			</Hero.Body>
		</Hero>
		<div className="spacer is-hidden-tablet"/>
		<Hero size="large" className="is-hidden-tablet"
		      style={{backgroundImage: `url(${imgSrcMin})`, backgroundSize: 'cover'}}>
			<Hero.Body>
				<h1 className="title page-title has-text-white is-spaced">{t(title)}</h1>
				<div className="slideInfo">
					<Button className='is-text' onClick={slidePrev}>
						<Icon className="slideBtnIcon">
							<ArrowLeft width="22px" height="40px"/>
						</Icon>
					</Button>
					<span className="currSlide">0{curr}</span>
					<Icon className="">
						<ImgBreakShape height="90px"/>
					</Icon>
					<span className="totalSlides">07</span>
					<Button className='is-text' onClick={slideNext}>
						<Icon className="slideBtnIcon">
							<ArrowRight width="22px" height="40px"/>
						</Icon>
					</Button>
				</div>
			</Hero.Body>
		</Hero>
		<section className="is-hidden-tablet section textSection has-text-left">
			<h2 className="subtitle page-subtitle ">{t(subtitle)}</h2>
			<h2 className="subtitle page-description ">{t(description)}</h2>
		</section>
	</>)
}

export default Trainer;
