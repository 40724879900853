import * as React from "react";

function SvgIcoLinkedin(props) {
  return (
    <svg width={25} height={25} viewBox="0 0 30 40" {...props}>
      <path
        fillRule="evenodd"
        d="M29.992 29.999V30h-6.206v-9.751c0-2.324-.047-5.304-3.234-5.304-3.233 0-3.728 2.525-3.728 5.136v9.918h-6.213V9.97h5.966v2.731h.088c.829-1.574 2.859-3.234 5.884-3.234 6.292 0 7.451 4.147 7.451 9.529v11.003h-.008zM3.602 7.239C1.614 7.239 0 5.591 0 3.602a3.604 3.604 0 017.205 0c0 1.989-1.614 3.637-3.603 3.637zM6.715 30H.495V9.971h6.22V30z"
      />
    </svg>
  );
}

export default SvgIcoLinkedin;
