import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Hero, Columns} from "react-bulma-components";
import school from '../img/img-cursuri-scoli-min.jpg';
import schoolMin from '../img/img-toate-cursurile-scoli-mobile-min.jpg';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

function SchoolClassPage({imgSrc, title, description, setNavClass}) {
    const { t } = useTranslation();

    useEffect(()=>{
        setNavClass('light');
    },[])

    return (<>
        <Helmet>
            <title>North Tennis | {t('classes.school.title')}</title>
        </Helmet>
        <Hero className="snap-point is-hidden-mobile" size="fullheight" style={{backgroundImage: `url(${school})`, backgroundSize: 'cover'}} >
        <Hero.Body>
            <Columns style={{width: '100%'}}>
                <Columns.Column
                    tablet={{
                        size: 'one-third',
                    }}
                    desktop={{
                        size: 'one-third',
                    }}
                    widescreen={{
                        size: 'one-third',
                    }}
                    fullhd={{
                        size: 'one-third',
                    }}
                >

                </Columns.Column>
                <Columns.Column
                    desktop={{
                        size: 'one-third',
                    }}
                    widescreen={{
                        size: 'one-third',
                    }}
                >
                </Columns.Column>
                <Columns.Column
                    tablet={{
                        size: 'half',
                    }}
                    desktop={{
                        size: 'one-third',
                    }}
                    widescreen={{
                        size: 'one-third',
                    }}
                    fullhd={{
                        size: 'one-third',
                    }}
                    className="has-text-left padded-right"
                >
                    <div className="class-title-wrapper">
                        <h1 className="title class-title has-text-white is-spaced">{t('classes.school.title')}</h1>
                        <Link to={'/contact'} className="price-link" >{t('classes.contactUs')}</Link>
                    </div>

                    <h2 className="subtitle page-description has-text-white" dangerouslySetInnerHTML={{__html: t('classes.school.description')}}/>
                    {/*} <div className="not-sure"> {t('classes.notSure')} </div>
                    <Link className="button main-btn" to={'/quiz'} style={{height: '50px', marginLeft: '0px'}}>{t('classes.takeQuiz')}</Link>*/}
                </Columns.Column>
            </Columns>
        </Hero.Body>
    </Hero>
        <div className="spacer is-hidden-tablet"/>
        <Hero className="is-hidden-tablet" size="large"
              style={{backgroundImage: `url(${schoolMin})`, backgroundSize: 'cover'}}>
            <Hero.Body> <h1 className="title class-title has-text-white is-spaced">{t('classes.school.title')}</h1></Hero.Body>
        </Hero>
        <section className="textSection is-hidden-tablet section has-text-left">
            <h2 className="subtitle page-description " dangerouslySetInnerHTML={{__html: t('classes.school.description')}}/>
            <Link to={'/contact'} className="price-link" >{t('classes.contactUs')}</Link>
            {/*}  <div className="not-sure"> {t('classes.notSure')} </div>
            <Link className="button main-btn" to={'/quiz'} style={{height: '50px', marginLeft: '0px'}}>{t('classes.takeQuiz')}</Link>*/}
        </section>
        </>)
}

export default SchoolClassPage;
