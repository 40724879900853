import * as React from "react";

function SvgIcoFacebook(props) {
  return (
    <svg width={12} height={25} {...props}>
      <path
        fillRule="evenodd"
        fill="#282828"
        d="M5.479 5.746V4.404c0-.642.434-.796.742-.796h1.874V.743H5.508c-2.869 0-3.512 2.124-3.512 3.494v1.509H.331v3.34H2.01v8.343h3.343V9.086h2.476l.112-1.314.196-2.026H5.479z"
      />
    </svg>
  );
}

export default SvgIcoFacebook;
