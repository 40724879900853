import * as React from "react";

function SvgArrowRight(props) {
  return (
    <svg width={22} height={40} {...props}>
      <path d="M22.013 20.188L2.02 40.013.592 38.597l18.445-18.291-19.05-18.89L1.428-.013l19.05 18.89.107-.105 1.428 1.416z" />
    </svg>
  );
}

export default SvgArrowRight;
