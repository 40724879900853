import React, {Suspense, useState} from 'react';
import './App.sass';
import './style.scss';
import MenuBar from "./components/MenuBar";
import FooterBar from "./components/FooterBar";
import Home from "./pages/Home";
import FreeLesson from "./pages/FreeLesson";
import Classes from "./pages/Classes";
import Services from "./pages/Services";

import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import AdultsClassPage from "./pages/AdultsClassPage";
import ContactPage from "./pages/ContactPage";

import AboutUs from "./pages/About";
import JuniorClassPage from "./pages/JuniorClassPage";
import CorporateClassPage from "./pages/CorporateClassPage";
import SchoolClassPage from "./pages/SchoolClassPage";
import PriceList from "./pages/PriceList";
import ScrollToTop from "./components/ScrollToTop";
import CookieBanner from "./components/CookieBanner";
import PageNotFound from "./pages/PageNotFound";
import Questionnaire from "./pages/Questionnaire";
import Booksport from "./pages/Booksport";
import VideoAnalysis from "./pages/VideoAnalysis";
import Membership from "./pages/Membership";

function App() {
    const [navClass, setNavClass] = useState('light');

  return (
      <Suspense fallback={null}>
          <div className="App">
                  <Router>
                      <ScrollToTop />
                      <MenuBar navbarClass={navClass}/>
                    <Switch>
                        <Route exact path="/" >
                            <Home setNavClass={setNavClass}/>
                        </Route>
                        <Route exact path="/free">
                            <FreeLesson setNavClass={setNavClass}/>
                        </Route>
                        <Route exact path="/booksport">
                            <Booksport setNavClass={setNavClass} />
                        </Route>
                        <Route exact path="/classes">
                            <Classes setNavClass={setNavClass}/>
                        </Route>
                        <Route exact path="/services">
                            <Services setNavClass={setNavClass}/>
                        </Route>
                        <Route exact path="/contact">
                            <ContactPage setNavClass={setNavClass}/>
                        </Route>
                        <Route exact path="/prices">
                            <PriceList setNavClass={setNavClass}/>
                        </Route>
                        <Route exact path="/about">
                            <AboutUs setNavClass={setNavClass}/>
                        </Route>
                        <Route exact path="/video-analysis">
                            <VideoAnalysis setNavClass={setNavClass}/>
                        </Route>
                        <Route exact path="/membership">
                            <Membership setNavClass={setNavClass}/>
                        </Route>
                        <Route exact path="/classes/adults">
                            <AdultsClassPage setNavClass={setNavClass} openItem={'intermediate'}/>
                        </Route>
                        <Route exact path="/classes/junior">
                            <JuniorClassPage setNavClass={setNavClass}/>
                        </Route>
                        <Route exact path="/classes/corporate" >
                            <CorporateClassPage setNavClass={setNavClass}/>
                        </Route>
                        <Route exact path="/classes/school" >
                            <SchoolClassPage setNavClass={setNavClass}/>
                        </Route>
                        <Route exact path="/quiz" >
                            <Questionnaire setNavClass={setNavClass}/>
                        </Route>
                        {/* eslint-disable-next-line no-undef */}
                        <Route component={PageNotFound} />
                    </Switch>
                    <FooterBar/>
                    <CookieBanner />
                </Router>

          </div>
      </Suspense>
  );
}

export default App;
