import React, { useEffect } from "react";
import Service from "../components/Service";
import s1 from "../img/servicii-serviciile-noastre-min.jpg";
import s1Min from "../img/servicii-serviciile-noastre-mobile-min.jpg";
import { useTranslation } from "react-i18next";
import scrollSnapPolyfill from "css-scroll-snap-polyfill";
import { Helmet } from "react-helmet";

function Booksport({ setNavClass }) {
  const { t } = useTranslation();

  useEffect(() => {
    scrollSnapPolyfill();
    setNavClass("dark");
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://img.booksport.ro/media/booksport.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="booksportClass">
      <Helmet>
        <title>North Tennis | {t("services.title")}</title>
      </Helmet>

      <div
        id="booksport"
        className="booksportStyle"
        club="12"
        slug="northtennis"
        color="#06a37c"
      ></div>
    </div>
  );
}

export default Booksport;
