import React from 'react';
import { useTranslation } from 'react-i18next';
import {jumpToElement} from "../util/jumpToElement";
import {Icon} from "react-bulma-components";
import Ellipse from "../icons/Ellipse";
import EllipseOutlined from "../icons/EllipseOutline";


function Breadcrumbs({tag, activeIndex, crumbs}) {
    const {t} = useTranslation();
    const onJumpToSection = jumpToElement(`${tag}-`);

    return (<div className="breadcrumbs-container is-hidden-mobile">
        {crumbs.map((crumb, index) => (
            <>
                <div className={`breadcrumb ${activeIndex === index ? "active" : ""}`} onClick={onJumpToSection(index)} >
                    <Icon>
                        {activeIndex === index ? <Ellipse /> :<EllipseOutlined />}
                    </Icon>
                    {t(crumb)}
                </div>
                {index < crumbs.length-1 && (<div className={'breadcrumb-divider'}>|</div>)}
            </>
        ))}
    </div>);

}
export default Breadcrumbs;
