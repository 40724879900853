import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

function CookieBanner() {
    const {t} = useTranslation();
    const [bannerVisible, setBannerVisible] = useState(false);

    useEffect(() => {
        const showCookieBanner = !localStorage.getItem('acceptCookies');
        if (showCookieBanner) {
            setBannerVisible(true);
        }
    }, []);


    const cookiesAgree = () => {
        localStorage.setItem('acceptCookies', 'true');
        setBannerVisible(false);
    }

    return (
        <div className={`cookie-banner ${bannerVisible ? '' : 'is-hidden'}`}>
            <div className='cookie-text-wrapper'>
                <h2 className={'cookie-title'}>{t('cookieBanner.title')}</h2>
                <p>{t('cookieBanner.text')}</p>
                <button className={"button main-btn is-hidden-tablet"} onClick={() => cookiesAgree()}>{t('cookieBanner.agree')}</button>
            </div>
            <button className={"button main-btn is-hidden-mobile"} onClick={() => cookiesAgree()}>{t('cookieBanner.agree')}</button>
        </div>)

}

export default CookieBanner;
