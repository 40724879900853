import React from "react";
import { Footer, Container, Columns, Icon } from "react-bulma-components";
import radioGuerilla from "../img/logo-radio-guerrila-colored.png";
import digestest from "../img/digestest.png";
import highEnergy from "../img/logo-high-energy-colored.png";
import { useTranslation } from "react-i18next";
import IcoInstagram from "../icons/IcoInstagram";
import IcoFacebook from "../icons/IcoFacebook";
import IcoWhatsapp from "../icons/IcoWhatsapp";
import IcoLinkedin from "../icons/IcoLinkedin";
import oneTennis from "../img/logo-one-tennis.jpg";
import clubRulesEn from "../documents/club_rules_regulations.pdf";
import clubRulesRo from "../documents/regulament_intern.pdf";

function FooterBar() {
  const { t, i18n } = useTranslation();

  return (
    <Footer className="snap-point">
      <Columns style={{ width: "100%" }}>
        <Columns.Column
          className={"is-hidden-tablet-only"}
          desktop={{
            size: "one-quarter",
          }}
          widescreen={{
            size: "one-quarter",
          }}
          fullhd={{
            size: "one-quarter",
          }}
        >
          <a
            href="https://onetennis.ro/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="One Tennis Logo"
              className="oneTennisImg"
              src={oneTennis}
            />
            <div className="oneTennis">{t("footer.oneTennis")}</div>
          </a>
        </Columns.Column>
        <Columns.Column
          tablet={{
            size: "half",
          }}
          desktop={{
            size: "one-quarter",
          }}
          widescreen={{
            size: "one-quarter",
          }}
          fullhd={{
            size: "one-quarter",
          }}
          style={{ marginTop: "auto", marginBottom: "auto" }}
        >
          <a
            href="https://highenergy.ro/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="High Energy Logo"
              className="sp-logo"
              height="33"
              width="190"
              src={highEnergy}
            />
          </a>
        </Columns.Column>
        <Columns.Column
          tablet={{
            size: "half",
          }}
          desktop={{
            size: "one-quarter",
          }}
          widescreen={{
            size: "one-quarter",
          }}
          fullhd={{
            size: "one-quarter",
          }}
          style={{ marginTop: "auto", marginBottom: "auto" }}
        >
          <a
            href="https://www.guerrillaradio.ro/avanpost/timp-liber/activitati/north-tennis-344"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Radio Guerilla Logo"
              className="sp-logo"
              height="60"
              width="60"
              src={radioGuerilla}
            />
            <div>Avanpost Radio Guerilla</div>
          </a>
        </Columns.Column>
        <Columns.Column
          tablet={{
            size: "half",
          }}
          desktop={{
            size: "one-quarter",
          }}
          widescreen={{
            size: "one-quarter",
          }}
          fullhd={{
            size: "one-quarter",
          }}
          style={{ marginTop: "auto", marginBottom: "auto" }}
        >
          <a
            href="https://www.digestest.ro/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Digestest Logo"
              className="sp-logo"
              height="120"
              width="240"
              src={digestest}
            />
          </a>
        </Columns.Column>
      </Columns>
      <Columns style={{ width: "100%" }} className={"border-top"}>
        <Columns.Column
          tablet={{
            size: "one-third",
          }}
          desktop={{
            size: "one-third",
          }}
          widescreen={{
            size: "one-third",
          }}
          fullhd={{
            size: "one-third",
          }}
        >
          <address className="flex-column">
            <div className="footerName">NORTH TENNIS</div>
            <div className="footerAddress">
              {t("footer.streetAddress")}
              <br />
              {t("footer.cityAddress")}
            </div>
          </address>
        </Columns.Column>
        <Columns.Column
          className="is-flex"
          tablet={{
            size: "one-third",
          }}
          desktop={{
            size: "one-third",
          }}
          widescreen={{
            size: "one-third",
          }}
        >
          <Container className="flex-column">
            <Container className="flex-row">
              <a
                className="social-link"
                href="https://www.facebook.com/North-Tennis-108224440971013"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon>
                  <IcoFacebook alt="Facebook" />
                </Icon>
              </a>
              <a
                className="social-link"
                href="https://www.instagram.com/tennisnorth"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon>
                  <IcoInstagram alt="Instagram" />
                </Icon>
              </a>
              <a
                className="social-link"
                href="https://wa.me/40746126015"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon>
                  <IcoWhatsapp alt="Whatsapp" />
                </Icon>
              </a>
              <a
                className="social-link"
                href="https://www.linkedin.com/company/north-tennis"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon>
                  <IcoLinkedin alt="LinkedIn" />
                </Icon>
              </a>
            </Container>
            <Container className="flex-row">
              <a href="mailto:office@northtennis.ro">office@northtennis.ro</a>
            </Container>
            <Container>
              {" "}
              <div className="footerAddress">0746 126 015 <br /> <a href="https://anpc.ro/ce-este-sal/">ANPC</a> <br />
        Toate preturile contin TVA <br />
        AT North Park SRL<br />
        CUI: RO41417502<br />
        J40/9518/2019<br />
        Adresa: Str. ING. Nicolae Teodorescu, nr. 32, sector 6, București</div>
            </Container>
          </Container>
        </Columns.Column>
        <Columns.Column
          tablet={{
            size: "one-third",
          }}
          desktop={{
            size: "one-third",
          }}
          widescreen={{
            size: "one-third",
          }}
          fullhd={{
            size: "one-third",
          }}
        >
          <Container className="flex-column footer-page-links">
            <a
              className="docLinks"
              target="_blank"
              rel="noopener noreferrer"
              href={`${i18n.language === "ro" ? clubRulesRo : clubRulesEn}`}
            >
              {t("footer.clubRules")}
            </a>
          </Container>
        </Columns.Column>
      </Columns>
    </Footer>
  );
}

export default FooterBar;
