import React from 'react';
import { Hero, Columns} from "react-bulma-components";
import Breadcrumbs from "./Breadcrumbs";
import {useTranslation} from "react-i18next";

function Service({imgSrc, imgSrcMin, title, description, index}) {
    const { t } = useTranslation();
    return (<><Hero id={`service-${index}`}  size="fullheight" className="snap-point is-hidden-mobile" style={{backgroundImage: `url(${imgSrc})`, backgroundSize: 'cover'}} >
        <Hero.Body>
            <Columns style={{width: '100%'}}>
                <Columns.Column
                    className="breadcrumb-column"
                    tablet={{
                        size: 'one-third',
                    }}
                    desktop={{
                        size: 'one-third',
                    }}
                    widescreen={{
                        size: 'one-third',
                    }}
                    fullhd={{
                        size: 'one-third',
                    }}
                >
                    <Breadcrumbs activeIndex={index} tag="service" crumbs={[t('services.title'), t('services.location.title'),t('services.bookCourt.title'),t('services.classes.title'),t('services.shop.title'),t('services.videoAnalysis.title') ]}/>
                </Columns.Column>
                <Columns.Column
                    desktop={{
                        size: 'one-third',
                    }}
                    widescreen={{
                        size: 'one-third',
                    }}
                    fullhd={{
                        size: 'one-third',
                    }}
                >
                </Columns.Column>
                <Columns.Column
                    tablet={{
                        size: 'two-thirds',
                    }}
                    desktop={{
                        size: 'one-third',
                    }}
                    widescreen={{
                        size: 'one-third',
                    }}
                    fullhd={{
                        size: 'one-third',
                    }}
                    className="has-text-left padded-right"
                >
                    <h1 className="title page-title has-text-white is-spaced is-uppercase">{title}</h1>
                    <h2 className="subtitle page-description has-text-white" dangerouslySetInnerHTML={{__html: description}}/>
                </Columns.Column>
            </Columns>
        </Hero.Body>
    </Hero>
        <div className="spacer is-hidden-tablet"/>
        <Hero id={`class-${index}`} size="large" className="is-hidden-tablet"  style={{backgroundImage: `url(${imgSrcMin})`, backgroundSize: 'cover'}} >
            <Hero.Body > <h1 className="title page-title has-text-white is-spaced">{title}</h1></Hero.Body>
        </Hero>
        <section className="textSection is-hidden-tablet section has-text-left">
            {/*<h1 className="title page-title has-text-white is-spaced is-uppercase">{title}</h1>*/}
            <h2 className="subtitle page-description" dangerouslySetInnerHTML={{__html: description}}/>
        </section>
        </>)
}

export default Service;
