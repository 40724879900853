import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LngDetector from 'i18next-browser-languagedetector';
import translationEn from "./locales/en.json";
import translationRO from "./locales/ro.json";

i18n
    .use(initReactI18next)
    .use(LngDetector)
    .init({
        resources: {
            en: {
                translation: translationEn,
            },
            ro: {
                translation: translationRO,
            },
        },
        fallbackLng: "ro",
        detection: {
            order: ["localStorage", "navigator"],
            caches: ["localStorage"]},
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
