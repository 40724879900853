import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Columns, Form} from "react-bulma-components";
import emailjs, {init} from 'emailjs-com';
import {Helmet} from "react-helmet";
import ConfirmationBanner from "../components/ConfirmationBanner";

init("user_LIO6R5i6XlblnGQ6uxRBN");
const {Field, Control, Input, Textarea} = Form;

function ContactPage({setNavClass}) {
    const {t} = useTranslation();

    const [state, setState] = useState({name: '', email: '', phone: '', message: ''})
    const [showConfirm, setShowConfirm] = useState(false);
    const [sending, setSending] = useState(false);

    useEffect(() => {
        setNavClass('dark');
    }, [])

    useEffect(()=> {
        if(showConfirm) {
            setTimeout(()=>{ setShowConfirm(false)}, 3000)
        }
    }, [showConfirm])

    const onChange = (evt) => {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value,
        });
    }

    const sendEmail = (event) => {
        event.preventDefault();
        setSending(true);
        const send = async () => {
            await emailjs.send("service_zub29ri", "template_lf1476r", {...state});
            setSending(false);
            setShowConfirm(true);
        };
        send();
    }


    return (
        <>
            <Helmet>
                <title>North Tennis | {t('contact.title')}</title>
            </Helmet>
            <Columns className="snap-point" style={{width: '100%'}}>
                <Columns.Column size='half'>
                    <div className="map">
                        <iframe title="map" width="100%" height="100%" frameBorder="0"
                                src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJMZ6qrEoDskARIDLnKsRy3WA&key=AIzaSyA6TtG5ruztNuoHC7nV-VB4dSmX31GCebg"
                                allowFullScreen/>
                    </div>
                </Columns.Column>
                <Columns.Column size='half' className='contact-column'>
                    <h1 className="title page-title has-text-black is-spaced has-text-left">{t("contact.title")}</h1>
                    <form className="contact-form">
                        <Field>
                            <Control>
                                <Input
                                    name="name"
                                    value={state.name}
                                    outlined="true"
                                    placeholder="Name"
                                    className="bg-transparent has-text-black is-large-mobile"
                                    onChange={onChange}
                                />
                            </Control>
                        </Field>
                        <Field>
                            <Control>
                                <Input name="email"
                                       value={state.email}
                                       outlined="true"
                                       type='email'
                                       placeholder="Email "
                                       className="bg-transparent has-text-black is-large-mobile"
                                       onChange={onChange}/>
                            </Control>
                        </Field>
                        <Field>
                            <Control>
                                <Input name="phone"
                                       value={state.phone}
                                       outlined="true"
                                       type='tel'
                                       placeholder="Phone number"
                                       className="bg-transparent has-text-black is-large-mobile"
                                       onChange={onChange}/>
                            </Control>
                        </Field>
                        <Field>
                            <Control>
                                <Textarea name="message"
                                          value={state.message}
                                          outlined="true"
                                          placeholder="Your message"
                                          className="bg-transparent has-text-black is-large-mobile"
                                          onChange={onChange}/>
                            </Control>
                        </Field>

                        <Field>
                            <Control className="contact-btn-control">
                                <Button className={"main-btn is-large-mobile"} type="submit"
                                        disabled={state.message === '' || state.name === '' || sending}
                                        onClick={sendEmail}>Submit</Button>
                            </Control>
                        </Field>
                    </form>
                    <div>{t("contact.byPhone")} 0746 126 015</div>
                </Columns.Column>
            </Columns>
            <ConfirmationBanner isVisible={showConfirm}/>
        </>);

}

export default ContactPage;
