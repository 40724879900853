import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Hero, Columns} from "react-bulma-components";
import adultClasses from '../img/img-cursuri-adulti-min.jpg';
import adultClassesMin from '../img/img-cursuri-adulti-mobile-min.jpg';
import Collapsible from 'react-collapsible';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";

function AdultsClassPage({imgSrc, title, description, setNavClass}) {
    let location = useLocation();
    const openItem = location.hash;
    const {t} = useTranslation();

    useEffect(() => {
        setNavClass('light');
    }, [])

    return (
        <>
            <Helmet>
                <title>North Tennis | {t('classes.adult.title')}</title>
            </Helmet>
            <Hero className="snap-point  is-hidden-mobile" size="fullheight"
                  style={{backgroundImage: `url(${adultClasses})`, backgroundSize: 'cover'}}>
                <Hero.Body>
                    <Columns style={{width: '100%'}}>
                        <Columns.Column
                            tablet={{
                                size: 'one-third',
                            }}
                            desktop={{
                                size: 'one-third',
                            }}
                            widescreen={{
                                size: 'one-third',
                            }}
                            fullhd={{
                                size: 'one-third',
                            }}
                        >
                        </Columns.Column>
                        <Columns.Column
                            desktop={{
                                size: 'one-third',
                            }}
                            widescreen={{
                                size: 'one-third',
                            }}
                        >
                        </Columns.Column>
                        <Columns.Column
                            tablet={{
                                size: 'half',
                            }}
                            desktop={{
                                size: 'one-third',
                            }}
                            widescreen={{
                                size: 'one-third',
                            }}
                            fullhd={{
                                size: 'one-third',
                            }}
                            className="has-text-left padded-right"
                        >
                            <div className="class-title-wrapper">
                                <h1 className="title class-title has-text-white is-spaced">{t('classes.adult.title')}</h1>
                                <Link to={'/prices'} className="price-link">{t('classes.seePrices')}</Link>
                            </div>

                            <Collapsible trigger={t('classes.adult.startup.title')} open={openItem === '#startup'}>
                                <p>{t('classes.adult.startup.description')}</p>
                            </Collapsible>
                            <Collapsible trigger={t('classes.adult.intermediate.title')}
                                         open={openItem === '#intermediate'}>
                                <p>{t('classes.adult.intermediate.description')}</p>
                            </Collapsible>
                            <Collapsible trigger={t('classes.adult.pro.title')} open={openItem === '#pro'}>
                                <p>{t('classes.adult.pro.description')}</p>
                            </Collapsible>
                            <Collapsible trigger={t('classes.adult.sparring.title')} open={openItem === '#sparring'}>
                                <p>{t('classes.adult.sparring.description')}</p>
                            </Collapsible>
                            <Collapsible trigger={t('classes.adult.family.title')} open={openItem === '#family'}>
                                <p>{t('classes.adult.family.description')}</p>
                            </Collapsible>
                            <Collapsible trigger={t('classes.adult.group.title')} open={openItem === '#group'}>
                                <p>{t('classes.adult.group.description')}</p>
                            </Collapsible>
                            <h2 className="subtitle page-description has-text-white">{description}</h2>
                            <div className="not-sure"> {t('classes.notSure')} </div>
                            <Link className="button main-btn" to={'/quiz'}
                                  style={{height: '50px', marginLeft: '0px'}}>{t('classes.takeQuiz')}</Link>
                        </Columns.Column>
                    </Columns>
                </Hero.Body>
            </Hero>
            <div className="spacer is-hidden-tablet"/>
            <Hero className="is-hidden-tablet" size="large"
                  style={{backgroundImage: `url(${adultClassesMin})`, backgroundSize: 'cover'}}>
                <Hero.Body>
                    <h1 className="title class-title is-spaced has-text-white">{t('classes.adult.title')}</h1>
                </Hero.Body>
            </Hero>
            <section className="textSection is-hidden-tablet section has-text-left">
                <Collapsible trigger={t('classes.adult.startup.title')} open={openItem === '#startup'}>
                    <p className={"page-description"}>{t('classes.adult.startup.description')}</p>
                </Collapsible>
                <Collapsible trigger={t('classes.adult.intermediate.title')} open={openItem === '#intermediate'}>
                    <p className={"page-description"}>{t('classes.adult.intermediate.description')}</p>
                </Collapsible>
                <Collapsible trigger={t('classes.adult.pro.title')} open={openItem === '#pro'}>
                    <p className={"page-description"}>{t('classes.adult.pro.description')}</p>
                </Collapsible>
                <Collapsible trigger={t('classes.adult.sparring.title')} open={openItem === '#sparring'}>
                    <p className={"page-description"}>{t('classes.adult.sparring.description')}</p>
                </Collapsible>
                <Collapsible trigger={t('classes.adult.family.title')} open={openItem === '#family'}>
                    <p className={"page-description"}>{t('classes.adult.family.description')}</p>
                </Collapsible>
                <Collapsible trigger={t('classes.adult.group.title')} open={openItem === '#group'}>
                    <p className={"page-description"}>{t('classes.adult.group.description')}</p>
                </Collapsible>
                <h2 className="subtitle page-description has-text-white">{description}</h2>
                <Link to={'/prices'} className="price-link">{t('classes.seePrices')}</Link>
                <div className="not-sure"> {t('classes.notSure')} </div>
                <Link className="button main-btn" to={'/quiz'}
                      style={{height: '50px', marginLeft: '0px'}}>{t('classes.takeQuiz')}</Link>
            </section>

        </>)
}

export default AdultsClassPage;
