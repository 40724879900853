import React, {useEffect, useState} from 'react';
import {Button, Columns, Form, Hero} from "react-bulma-components";
import freeLesson from '../img/img-free-lesson-min.jpg';
import freeLessonMobile from '../img/img-free-lesson-mobile-min.jpg';
import {useTranslation} from "react-i18next";
import emailjs, {init} from 'emailjs-com';
import {Helmet} from "react-helmet";
import ConfirmationBanner from "../components/ConfirmationBanner";

init("user_LIO6R5i6XlblnGQ6uxRBN");

const {Field, Control, Input} = Form;

function FreeLesson({setNavClass}) {
    const {t} = useTranslation();
    const [showConfirm, setShowConfirm] = useState(false);
    const [phone, setPhone] = useState('');
    const [sending, setSending] = useState(false);
    const regex = new RegExp(
        /^[0-9+]{6,20}$/,
    );

    useEffect(() => {
        setNavClass('light');
    }, [])

    const sendEmail = (event) => {
        event.preventDefault();
        setSending(true);
        const send = async () => {
            await emailjs.send("service_zub29ri", "template_84xdxo9", {phoneNr: phone});
            setSending(false);
            setShowConfirm(true);
        }
        send();
    }

    useEffect(()=> {
        if(showConfirm) {
            setTimeout(()=>{ setShowConfirm(false)}, 3000)
        }
    }, [showConfirm])

    const onChange = (evt) => {
        const value = evt.target.value;
        setPhone(value);
    }

    return (
        <>
            <Helmet>
                <title>North Tennis | {t('freeLesson.title')}</title>
            </Helmet>
            <Hero className="snap-point is-hidden-mobile" size="fullheight"
                  style={{backgroundImage: `url(${freeLesson})`, backgroundSize: 'cover'}}>
                <Hero.Body>
                    <Columns style={{width: '100%'}}>
                        <Columns.Column
                            tablet={{
                                size: 'one-third',
                            }}
                            desktop={{
                                size: 'one-third',
                            }}
                            widescreen={{
                                size: 'one-third',
                            }}
                            fullhd={{
                                size: 'one-third',
                            }}
                        />
                        <Columns.Column
                            desktop={{
                                size: 'one-third',
                            }}
                            widescreen={{
                                size: 'one-third',
                            }}
                            fullhd={{
                                size: 'one-third',
                            }}
                        />
                        <Columns.Column
                            tablet={{
                                size: 'two-thirds',
                            }}
                            desktop={{
                                size: 'one-third',
                            }}
                            widescreen={{
                                size: 'one-third',
                            }}
                            fullhd={{
                                size: 'one-third',
                            }}
                            className="has-text-left padded-right"
                        >
                            <h1 className="title page-title has-text-white is-spaced">{t('freeLesson.title')}</h1>
                            <h2 className="subtitle page-description has-text-white">{t('freeLesson.description')}</h2>
                            <Field>
                                <Control>
                                    <Input outlined={"true"}
                                           placeholder={t('freeLesson.placeholder')}
                                           className="bg-transparent"
                                           onChange={onChange}
                                           type='tel'
                                           value={phone}
                                    />
                                </Control>
                            </Field>
                            <Field>
                                <Control>
                                    <Button className={"main-btn"} type="primary"
                                            disabled={!regex.test(phone) }
                                            onClick={sendEmail}>Submit</Button>
                                </Control>
                            </Field>
                        </Columns.Column>
                    </Columns>
                </Hero.Body>
            </Hero>
            <div className="spacer is-hidden-tablet"/>
            <Hero className="is-hidden-tablet" size="large"
                  style={{backgroundImage: `url(${freeLessonMobile})`, backgroundSize: 'cover'}}>
                <Hero.Body>
                    <h1 className="title page-title has-text-white is-spaced">{t('freeLesson.title')}</h1>
                </Hero.Body>
            </Hero>
            <section className="textSection is-hidden-tablet section">
                <h2 className="subtitle page-description  has-text-left">{t('freeLesson.description')}</h2>
                <Field className="has-text-left">
                    <Control>
                        <Input outlined={"true"}
                               type='tel'
                               placeholder={t('freeLesson.placeholder')}
                               className="bg-transparent is-large"
                               onChange={onChange}
                               value={phone}
                        />
                    </Control>
                </Field>
                <Field>
                    <Control>
                        <Button className={"main-btn is-large"} type="primary"
                                disabled={!regex.test(phone) || sending}
                                onClick={sendEmail}>Submit</Button>
                    </Control>
                </Field>
            </section>
            <ConfirmationBanner isVisible={showConfirm}/>
        </>
    );
}

export default FreeLesson;
