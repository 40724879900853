import React, {useEffect} from 'react';
import ServiceSlide from "../components/ServiceSlide";
import s1 from '../img/analysis-1.jpg';
import s2 from '../img/analysis-2.jpg';
import s3 from '../img/analysis-3.jpg';
import s4 from '../img/analysis-4.jpg';
import s1Min from '../img/analysis-1-min.jpg';
import s2Min from '../img/analysis-2-min.jpg';
import s3Min from '../img/analysis-3-min.jpg';
import s4Min from '../img/analysis-4-min.jpg';
import {useTranslation} from "react-i18next";
import scrollSnapPolyfill from "css-scroll-snap-polyfill";
import {Helmet} from "react-helmet";


function VideoAnalysis({setNavClass}) {
  const { t } = useTranslation();

  useEffect(() => {
      scrollSnapPolyfill();
      setNavClass('light');
  },[]);

  return (
      <>
      <Helmet>
          <title>North Tennis | {t('video.info.title')}</title>
      </Helmet>
      <div className="snap-point">
          <ServiceSlide imgSrc={s1} imgSrcMin={s1Min} title={t('video.info.title')} description={t('video.info.description')} index={0}/>
          <ServiceSlide imgSrc={s2} imgSrcMin={s2Min} title={t('video.4k.title')} description={t('video.4k.description')} index={1}/>
          <ServiceSlide imgSrc={s3} imgSrcMin={s3Min} title={t('video.professional.title')} description={t('video.professional.description')} index={2}/>
          <ServiceSlide imgSrc={s4} imgSrcMin={s4Min} title={t('video.performace.title')} description={t('video.performace.description')} index={3}/>
      </div>
      </>
  );
}

export default VideoAnalysis;
