import * as React from "react";

function SvgEllipseOutline(props) {
  return (
    <svg width={20} height={19} {...props}>
      <path
        stroke="#FFF"
        strokeWidth={2}
        opacity={0.702}
        fill="none"
        d="M9.016 1c4.426 0 8.015 3.582 8.015 8s-3.589 8-8.015 8C4.589 17 1 13.418 1 9s3.589-8 8.016-8z"
      />
    </svg>
  );
}

export default SvgEllipseOutline;
