import * as React from "react";

function SvgIcoInstagram(props) {
  return (
    <svg width={26} height={25} {...props}>
      <path
        fillRule="evenodd"
        fill="#282828"
        d="M15.921 22.003H6.34c-3.491 0-6.331-2.809-6.331-6.262V6.262C.009 2.81 2.849 0 6.34 0h9.581c3.492 0 6.331 2.81 6.331 6.262v9.479c0 3.453-2.839 6.262-6.331 6.262zm4.424-15.741c0-2.413-1.984-4.376-4.424-4.376H6.34c-2.44 0-4.425 1.963-4.425 4.376v9.479c0 2.413 1.985 4.376 4.425 4.376h9.581c2.44 0 4.424-1.963 4.424-4.376V6.262zM17.07 6.4a1.362 1.362 0 01-1.369-1.354c0-.748.613-1.354 1.369-1.354s1.369.606 1.369 1.354c0 .747-.613 1.354-1.369 1.354zm-5.939 10.235c-3.141 0-5.696-2.527-5.696-5.634 0-3.106 2.555-5.633 5.696-5.633 3.14 0 5.694 2.527 5.694 5.633 0 3.107-2.554 5.634-5.694 5.634zm0-9.381c-2.09 0-3.789 1.682-3.789 3.747 0 2.067 1.699 3.749 3.789 3.749 2.089 0 3.788-1.682 3.788-3.749 0-2.065-1.699-3.747-3.788-3.747z"
      />
    </svg>
  );
}

export default SvgIcoInstagram;
