import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

function ConfirmationBanner({isVisible}) {
    const {t} = useTranslation();
    const [active, setActive] = useState(isVisible);

    useEffect(()=>{
        setActive(isVisible);
    },[isVisible])

    return (
        <div className={`modal ${active ? 'is-active': ''}`} onClick={()=> setActive(false)}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <div className={"box"}>
                    {t('emailSent')}
                </div>
            </div>
        </div>)

}

export default ConfirmationBanner;
