import React from 'react';
import {Button, Hero, Columns, Icon} from "react-bulma-components";
import {useTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import ArrowLeft from "../icons/ArrowLeft";
import ArrowRight from "../icons/ArrowRight";
import ImgBreakShape from "../icons/ImgBreakShape";


function Slide({imgSrc, imgSrcSmall, slidePrev, slideNext, title, description, curr, linkTo, btnText}) {
    const {t} = useTranslation();
    return (<>
        <Hero className="is-hidden-mobile" size="fullheight"
              style={{backgroundImage: `url(${imgSrc})`, backgroundSize: 'cover'}}>
            <Hero.Body>
                <Columns style={{width: '100%'}}>
                    <Columns.Column
                        tablet={{
                            size: 'one-third',
                        }}
                        desktop={{
                            size: 'one-third',
                        }}
                        widescreen={{
                            size: 'one-third',
                        }}
                        fullhd={{
                            size: 'one-third',
                        }}
                        style={{margin:'auto'}}
                    >
                        <div className="slideInfo">
                            <Button className='is-text' onClick={slidePrev}>
                                <Icon className="slideBtnIcon">
                                    <ArrowLeft width="22px" height="40px"/>
                                </Icon>
                            </Button>
                            <span className="currSlide">0{curr}</span>
                            <Icon className="">
                                <ImgBreakShape height="90px"/>
                            </Icon>
                            <span className="totalSlides">07</span>
                            <Button className='is-text' onClick={slideNext}>
                                <Icon className="slideBtnIcon">
                                    <ArrowRight width="22px" height="40px"/>
                                </Icon>
                            </Button>
                        </div>
                    </Columns.Column>
                    <Columns.Column
                        desktop={{
                            size: 'one-third',
                        }}
                        widescreen={{
                            size: 'one-third',
                        }}
                        fullhd={{
                            size: 'one-third',
                        }}
                    >
                    </Columns.Column>
                    <Columns.Column
                        tablet={{
                            size: 'two-thirds',
                        }}
                        desktop={{
                            size: 'one-third',
                        }}
                        widescreen={{
                            size: 'one-third',
                        }}
                        fullhd={{
                            size: 'one-third',
                        }}
                        className="has-text-left padded-right"
                    >
                        <h1 className="title page-title has-text-white is-spaced">{t(title)}</h1>
                        <h2 className="subtitle page-description has-text-white">{t(description)}</h2>
                        <Link className="button main-btn" style={{width: '260px', height: '50px', marginLeft: '0px'}}
                              to={linkTo}>{btnText}</Link>
                    </Columns.Column>
                </Columns>
            </Hero.Body>
        </Hero>
        <div className="spacer is-hidden-tablet"/>
        <Hero size="large" className="is-hidden-tablet"
              style={{backgroundImage: `url(${imgSrcSmall})`, backgroundSize: 'cover'}}>
            <Hero.Body>
                <h1 className="title page-title has-text-white is-spaced">{t(title)}</h1>
                <div className="slideInfo">
                    <Button className='is-text' onClick={slidePrev}>
                        <Icon className="slideBtnIcon">
                            <ArrowLeft width="22px" height="40px"/>
                        </Icon>
                    </Button>
                    <span className="currSlide">0{curr}</span>
                    <Icon className="">
                        <ImgBreakShape height="90px"/>
                    </Icon>
                    <span className="totalSlides">07</span>
                    <Button className='is-text' onClick={slideNext}>
                        <Icon className="slideBtnIcon">
                            <ArrowRight width="22px" height="40px"/>
                        </Icon>
                    </Button>
                </div>
            </Hero.Body>
        </Hero>
        <section className="textSection is-hidden-tablet section">
            <h2 className="subtitle page-description">{t(description)}</h2>
            <Link className="button main-btn has-text-left" style={{ height: '50px', marginLeft: '0px'}}
                  to={linkTo}>{btnText}</Link>
        </section>
    </>)
}

export default Slide;
