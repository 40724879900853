import React, {useEffect} from 'react';
import Service from "../components/Service";
import s1 from '../img/servicii-serviciile-noastre-min.jpg';
import s2 from '../img/servicii-locatie-facilitatii-min.jpg';
import s3 from '../img/services-inchiriat-terenuri-tenis-min.jpg';
import s4 from '../img/servicii-cursuri-tenis-evenimente-min.jpg';
import s5 from '../img/servicii-magazin-sportiv-min.jpg';
import s6 from '../img/analysis-1.jpg';
import s1Min from '../img/servicii-serviciile-noastre-mobile-min.jpg';
import s2Min from '../img/servicii-locatie-facilitatii-mobile-min.jpg';
import s3Min from '../img/services-inchiriat-terenuri-tenis-mobile-min.jpg';
import s4Min from '../img/servicii-cursuri-tenis-evenimente-mobile-min.jpg';
import s5Min from '../img/servicii-magazin-sportiv-mobile-min.jpg';
import s6Min from '../img/analysis-1-min.jpg';
import {useTranslation} from "react-i18next";
import scrollSnapPolyfill from "css-scroll-snap-polyfill";
import {Helmet} from "react-helmet";

function Services({setNavClass}) {
    const { t } = useTranslation();

    useEffect(() => {
        scrollSnapPolyfill();
        setNavClass('light');
    },[]);

    return (
        <>
        <Helmet>
            <title>North Tennis | {t('services.title')}</title>
        </Helmet>
        <div className="snap-point">
            <Service imgSrc={s1} imgSrcMin={s1Min} title={t('services.title')} description={t('services.description')} index={0}/>
            <Service imgSrc={s2} imgSrcMin={s2Min} title={t('services.location.title')} description={t('services.location.description')} index={1}/>
            <Service imgSrc={s3} imgSrcMin={s3Min} title={t('services.bookCourt.title')} description={t('services.bookCourt.description')} index={2}/>
            <Service imgSrc={s4} imgSrcMin={s4Min} title={t('services.classes.title')} description={t('services.classes.description')} index={3}/>
            <Service imgSrc={s5} imgSrcMin={s5Min} title={t('services.shop.title')} description={t('services.shop.description')} index={4}/>
            <Service imgSrc={s6} imgSrcMin={s6Min} title={t('services.videoAnalysis.title')} description={t('services.videoAnalysis.description')} index={5}/>
        </div>
        </>
    );
}

export default Services;
