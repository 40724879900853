import * as React from "react";

function SvgEllipse(props) {
  return (
    <svg width={25} height={24} {...props}>
      <path
        fillRule="evenodd"
        fill="#FFF"
        d="M12.016 0c6.636 0 12.015 5.372 12.015 12 0 6.627-5.379 12-12.015 12C5.38 24 0 18.627 0 12 0 5.372 5.38 0 12.016 0z"
      />
    </svg>
  );
}

export default SvgEllipse;
