import React, {useEffect} from 'react';
import ServiceSlide from "../components/ServiceSlide";
import m1 from '../img/membership-1.jpg';
import m2 from '../img/membership-2.jpg';
import m3 from '../img/membership-3.jpg';
import m1Min from '../img/membership-1-min.jpg';
import m2Min from '../img/membership-2-min.jpg';
import m3Min from '../img/membership-3-min.jpg';
import {useTranslation} from "react-i18next";
import scrollSnapPolyfill from "css-scroll-snap-polyfill";
import {Helmet} from "react-helmet";


function Membership({setNavClass}) {
  const { t } = useTranslation();

  useEffect(() => {
      scrollSnapPolyfill();
      setNavClass('light');
  },[]);

  return (
      <>
      <Helmet>
          <title>North Tennis | {t('membership.info.title')}</title>
      </Helmet>
      <div className="snap-point">
          <ServiceSlide imgSrc={m1} imgSrcMin={m1Min} title={t('membership.info.title')} description={t('membership.info.description')} index={0}/>
          <ServiceSlide imgSrc={m2} imgSrcMin={m2Min} title={t('membership.monthly.title')} description={t('membership.monthly.description')} index={1}/>
          <ServiceSlide imgSrc={m3} imgSrcMin={m3Min} title={t('membership.seasonal.title')} description={t('membership.seasonal.description')} index={2}/>
      </div>
      </>
  );
}

export default Membership;
