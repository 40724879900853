import * as React from "react";

function SvgImgBreakShape(props) {
  return (
    <svg width={2} height={90} {...props}>
      <path fill="#FFF" d="M2 90H0V0h2v90z" />
    </svg>
  );
}

export default SvgImgBreakShape;
