import React, {useEffect, useRef} from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import {useTranslation} from 'react-i18next';

import about from '../img/img-about-us-1-trofee-min.jpg'
import andi from '../img/img-about-us-2-andi-min.jpg';
import florin from '../img/img-about-us-3-florin-min.jpg';
import monica from '../img/img-about-us-4-monica-min.jpg';
import vlad from '../img/img-about-us-5-vlad-min.jpg';
import nicolescu from '../img/img-about-us-6-nicolescu-min.jpg';
import dorian from '../img/dorian-desktop.jpg';

import aboutMin from '../img/img-about-us-1-trofee-mobile-min.jpg'
import andiMin from '../img/img-about-us-2-andi-mobile-min.jpg';
import florinMin from '../img/img-about-us-3-florin-mobile-min.jpg';
import monicaMin from '../img/img-about-us-4-monica-mobile-min.jpg';
import vladMin from '../img/img-about-us-5-vlad-mobile-min.jpg';
import nicolescuMin from '../img/img-about-us-6-nicolescu-mobile-min.jpg';
import dorianMin from '../img/dorian-mobile.jpg';

import Trainer from "../components/Trainer";
import {Helmet} from "react-helmet";

function AboutUs({setNavClass}) {
    const {t} = useTranslation();
    const carouselEl = useRef(null);
    const slidePrev = () => carouselEl.current.slidePrev();
    const slideNext = () => carouselEl.current.slideNext();

    useEffect(() => {
        setNavClass('light');
    }, [])

    return (
        <>
            <Helmet>
                <title>North Tennis | {t('about.title')}</title>
            </Helmet>
            <div className="snap-point">
                <AliceCarousel dotsDisabled={false}
                               buttonsDisabled={true}
                               touchTrackingEnabled={true}
                               mouseTrackingEnabled={true}
                               ref={carouselEl}>
                    <Trainer imgSrc={about} imgSrcMin={aboutMin} slidePrev={slidePrev} slideNext={slideNext} curr={1}
                             title={'about.title'} description={'about.description'}/>
                    <Trainer imgSrc={andi} imgSrcMin={andiMin} slidePrev={slidePrev} slideNext={slideNext} curr={2}
                             title={'about.andi.title'} subtitle={'about.andi.subtitle'}
                             description={'about.andi.description'}/>
                    <Trainer imgSrc={florin} imgSrcMin={florinMin} slidePrev={slidePrev} slideNext={slideNext} curr={3}
                             title={'about.florin.title'} subtitle={'about.florin.subtitle'}
                             description={'about.florin.description'}/>
                    <Trainer imgSrc={vlad} imgSrcMin={vladMin} slidePrev={slidePrev} slideNext={slideNext} curr={4}
                             title={'about.vlad.title'} subtitle={'about.vlad.subtitle'}
                             description={'about.vlad.description'}/>
                    <Trainer imgSrc={monica} imgSrcMin={monicaMin} slidePrev={slidePrev} slideNext={slideNext} curr={5}
                             title={'about.monica.title'} subtitle={'about.monica.subtitle'}
                             description={'about.monica.description'}/>
                    <Trainer imgSrc={nicolescu} imgSrcMin={nicolescuMin} slidePrev={slidePrev} slideNext={slideNext} curr={6}
                             title={'about.nicolescu.title'} subtitle={'about.nicolescu.subtitle'}
                             description={'about.nicolescu.description'}/>
                    <Trainer imgSrc={dorian} imgSrcMin={dorianMin} slidePrev={slidePrev} slideNext={slideNext} curr={7}
                             title={'about.dorian.title'} subtitle={'about.dorian.subtitle'}
                             description={'about.dorian.description'}/>
                </AliceCarousel>
            </div>
        </>
    );
}

export default AboutUs;
