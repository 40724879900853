import React, {useState} from 'react';
import { Navbar } from 'react-bulma-components';
import {Link} from "react-router-dom";
import logo from '../img/logo.png'
import {useTranslation} from "react-i18next";

function MenuBar({navbarClass}) {
    const [open, setOpen] = useState(false);

    const {t, i18n} = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);

    };
    return (
        <Navbar
            className={navbarClass}
            active={open}
        >
            <Navbar.Brand>
                <Navbar.Item renderAs={Link} to={"/"}>
                    <img className="logo" src={logo} alt="North Tennis"/>
                </Navbar.Item>
            
                <div className="call-btn is-hidden-tablet">
                  <a href="tel:+40746126015" style={{color: "white"}}>
                        {t('menu.callUs')}
                  </a>
                </div>
                <Navbar.Burger onClick={() => setOpen(!open)}/>
              
            </Navbar.Brand>
            <Navbar.Menu >
                <Navbar.Container position="end" onClick={() => setOpen(false)}>                    
                    <Navbar.Item renderAs={Link} to="/booksport">
                        {t("menu.book")}
                    </Navbar.Item>
                    <Navbar.Item renderAs={Link}  to="/free">
                        {t('menu.free')}
                    </Navbar.Item>
                    <Navbar.Item renderAs={Link} to="/classes">
                        {t('menu.classes')}
                    </Navbar.Item>
                    <Navbar.Item renderAs={Link}  to="/services">
                        {t('menu.services')}
                    </Navbar.Item>
                    <Navbar.Item renderAs={Link}  to="/video-analysis">
                        {t('menu.video')}
                    </Navbar.Item>
                    <Navbar.Item renderAs={Link}  to="/contact">
                        {t('menu.contact')}
                    </Navbar.Item>
                    <Navbar.Item renderAs={Link}  to="/prices">
                        {t('menu.prices')}
                    </Navbar.Item>
                    <Navbar.Item renderAs={Link}  to="/about">
                        {t('menu.about')}
                    </Navbar.Item>
                    <Navbar.Item className="lang-menu">
                        <div className={`lang ${i18n.language ==='ro'? 'active': ''}`} onClick={() => changeLanguage('ro')}>ro</div>
                    </Navbar.Item>
                    <span className="lang-divider">{" | "}</span>
                    <Navbar.Item className="lang-menu">
                        <div className={`lang ${i18n.language ==='en'? 'active': ''}`} onClick={() => changeLanguage('en')}>en</div>
                    </Navbar.Item>
                </Navbar.Container>
            </Navbar.Menu>
        </Navbar>
    );
}

export default MenuBar;
