import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import { Columns, Hero} from "react-bulma-components";
import corporateImg from '../img/img-cursuri-corporate-min.jpg';
import corporateMin from '../img/img-cursuri-corporate-mobile-min.jpg';
import Collapsible from 'react-collapsible';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

function CorporateClassPage({imgSrc, title, description, setNavClass}) {
    const {t} = useTranslation();

    useEffect(() => {
        setNavClass('light');
    }, [])

    return (
        <>
            <Helmet>
                <title>North Tennis | {t('classes.corporate.title')}</title>
            </Helmet>
            <Hero className="snap-point is-hidden-mobile" size="fullheight"
                  style={{backgroundImage: `url(${corporateImg})`, backgroundSize: 'cover'}}>
                <Hero.Body>
                    <Columns style={{width: '100%'}}>
                        <Columns.Column
                            mobile={{
                                size: 'half',
                            }}
                            tablet={{
                                size: 'one-third',
                            }}
                            desktop={{
                                size: 'one-third',
                            }}
                            widescreen={{
                                size: 'one-third',
                            }}
                            fullhd={{
                                size: 'one-third',
                            }}
                        >

                        </Columns.Column>
                        <Columns.Column
                            desktop={{
                                size: 'one-third',
                            }}
                            widescreen={{
                                size: 'one-third',
                            }}
                        >
                        </Columns.Column>
                        <Columns.Column
                            mobile={{
                                size: 'half',
                            }}
                            tablet={{
                                size: 'half',
                            }}
                            desktop={{
                                size: 'one-third',
                            }}
                            widescreen={{
                                size: 'one-third',
                            }}
                            fullhd={{
                                size: 'one-third',
                            }}
                            className="has-text-left has-text-white padded-right"
                        >
                            <div className="class-title-wrapper">
                                <h1 className="title class-title has-text-white is-spaced">{t('classes.corporate.title')}</h1>
                                <Link to={'/contact'} className="price-link" >{t('classes.contactUs')}</Link>
                            </div>
                            <p style={{marginBottom:'1rem'}}>{t('classes.corporate.description')}</p>

                            <Collapsible trigger={t('classes.corporate.teamBuilding.title')}>
                                <p>{t('classes.corporate.teamBuilding.description')}</p>
                            </Collapsible>
                            <Collapsible trigger={t('classes.corporate.bookCourt.title')}>
                                <p>{t('classes.corporate.bookCourt.description')}</p>
                            </Collapsible>
                            <Collapsible trigger={t('classes.corporate.lesson.title')}>
                                <p>{t('classes.corporate.lesson.description')}</p>
                            </Collapsible>
                            <h2 className="subtitle page-description has-text-white">{description}</h2>
                            {/*  <div className="not-sure"> {t('classes.notSure')} </div>
                            <Link className="button main-btn" to={'/quiz'} style={{height: '50px', marginLeft: '0px'}}>{t('classes.takeQuiz')}</Link>*/}
                        </Columns.Column>
                    </Columns>
                </Hero.Body>
            </Hero>
            <Hero className="is-hidden-tablet" size="large"
                  style={{backgroundImage: `url(${corporateMin})`, backgroundSize: 'cover'}}>
                <Hero.Body>
                    <h1 className="title class-title has-text-white is-spaced">{t('classes.corporate.title')}</h1>
                </Hero.Body>
            </Hero>
            <section className="textSection is-hidden-tablet section has-text-left">
                <Collapsible trigger={t('classes.corporate.teamBuilding.title')}>
                    <p>{t('classes.corporate.teamBuilding.description')}</p>
                </Collapsible>
                <Collapsible trigger={t('classes.corporate.bookCourt.title')}>
                    <p>{t('classes.corporate.bookCourt.description')}</p>
                </Collapsible>
                <Collapsible trigger={t('classes.corporate.lesson.title')}>
                    <p>{t('classes.corporate.lesson.description')}</p>
                </Collapsible>
                <Link to={'/contact'} className="price-link" >{t('classes.contactUs')}</Link>
                {/*}  <div className="not-sure"> {t('classes.notSure')} </div>
                <Link className="button main-btn" to={'/quiz'} style={{height: '50px', marginLeft: '0px'}}>{t('classes.takeQuiz')}</Link>*/}
            </section>
        </>)
}

export default CorporateClassPage;
