import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import Class from "../components/Class";
import all from '../img/img-toatecursurile-cursurile-noastre-min.jpg';
import allSmall from '../img/img-toatecursurile-cursurile-noastre-mobile-min.jpg';
import adult from '../img/img-toate-cursurile-adult-min.jpg';
import adultSmall from '../img/img-toate-cursurile-adult-mobile-min.jpg';
import junior from '../img/img-toate-cursurile-juniori-min.jpg';
import juniorSmall from '../img/img-toate-cursurile-juniori-mobile-min.jpg';
import corporate from '../img/img-toate-cursurile-corporate-min.jpg';
import corporateSmall from '../img/img-toate-cursurile-corporate-mobile-min.jpg';
import school from '../img/img-toate-cursurile-scoli-min.jpg';
import schoolSmall from '../img/img-toate-cursurile-scoli-mobile-min.jpg';
import scrollSnapPolyfill from 'css-scroll-snap-polyfill'
import {Helmet} from "react-helmet";

function Classes({setNavClass}) {
    const {t} = useTranslation();

    useEffect(() => {
        scrollSnapPolyfill();
        setNavClass('light');
    }, []);

    return (
        <>
            <Helmet>
                <title>North Tennis | {t('classes.title')}</title>
            </Helmet>
            <div className="snap-point">
                <scroll-container>
                    <Class imgSrc={all} imgSrcMobile={allSmall} title={t('classes.title')}
                           description={t('classes.description')} btnText={t('classes.findProgram')}
                           linkTo={"/quiz"}
                           index={0}/>
                    <Class imgSrc={junior} imgSrcMobile={juniorSmall} title={t('classes.junior.title')}
                           description={t('classes.junior.description')} btnText={t('classes.seeClasses')}
                           linkTo={"/classes/junior"} index={1}/>
                    <Class imgSrc={adult} imgSrcMobile={adultSmall} title={t('classes.adult.title')}
                           description={t('classes.adult.description')} btnText={t('classes.seeClasses')}
                           linkTo={"/classes/adults"} index={2}/>
                    <Class imgSrc={corporate} imgSrcMobile={corporateSmall} title={t('classes.corporate.title')}
                           description={t('classes.corporate.description')} btnText={t('classes.seeClasses')}
                           linkTo={"/classes/corporate"} index={3}/>
                    <Class imgSrc={school} imgSrcMobile={schoolSmall} title={t('classes.school.title')}
                           description={t('classes.school.description')} btnText={t('classes.seeClasses')}
                           linkTo={"/classes/school"} index={4}/>
                </scroll-container>
            </div>
        </>
    );
}

export default Classes;
