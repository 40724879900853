import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import quiz from "../quiz.json";
import {Button, Columns} from "react-bulma-components";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

function Questionnaire({setNavClass}) {
    const [active, setActive] = useState(0)
    const [answers, setAnswers] = useState([])
    const [result, setResult] = useState(null);
    const {t} = useTranslation();
    const {questions, results} = quiz;

    useEffect(() => {
        setNavClass('dark');
    }, []);

    const goBack = () => {
        setActive(prev => prev - 1);
    }

    const goNext = () => {
        setActive(prev => prev + 1);
    }

    const setAnswer = (qIndex, answerIndex) => {
        const newAnswers = JSON.parse(JSON.stringify(answers));
        newAnswers[qIndex] = answerIndex;
        setAnswers(newAnswers);
    }

    const getResult = () => {
        const age = answers[0];
        const duration = answers[1];
        const hours = answers[2];
        const trainer = answers[3];
        const goal = answers[4];

        let startup = 0;
        let intermediate = 0;

        let resultKey;

        if (age < 3) {
            //junior
            if (goal === 3 && age < 2) {
                resultKey = 'junior.ace';
            } else {

                if (duration < 2) {
                    startup++;
                } else {
                    intermediate++;
                }

                if (hours < 2) {
                    startup++;
                } else {
                    intermediate++;
                }

                if (trainer < 1) {
                    startup++;
                } else {
                    intermediate++;
                }

                if (startup > intermediate) {
                    resultKey = "junior.novice";
                } else {
                    resultKey = "junior.intermediate";
                }
            }
        } else {
            //adult
            if (goal === 1) {
                resultKey = 'adult.sparring';
            } else {
                if (duration < 2) {
                    startup++;
                } else {
                    intermediate++;
                }

                if (hours < 2) {
                    startup++;
                } else {
                    intermediate++;
                }

                if (trainer < 1) {
                    startup++;
                } else {
                    intermediate++;
                }

                if (startup > intermediate) {
                    resultKey = "adult.startup";
                } else {
                    resultKey = "adult.intermediate";
                }
            }
        }
        setResult(results.find(r => r.key === resultKey));
    }

    return (
        <>
            <Helmet>
                <title>North Tennis | {t('quiz.title')}</title>
            </Helmet>
            <Columns className="snap-point" style={{width: '100%'}}>
                <Columns.Column
                    className={'is-hidden-mobile no-pad'}
                    tablet={{
                        size: 'one-quarter',
                    }}
                    desktop={{
                        size: 'one-third',
                    }}
                    widescreen={{
                        size: 'half',
                    }}
                    fullhd={{
                        size: 'half',
                    }}>
                    <div className="map quiz-bg"/>
                </Columns.Column>
                <Columns.Column
                    className='contact-column'
                    tablet={{
                        size: 'three-quarters',
                    }}
                    desktop={{
                        size: 'two-thirds',
                    }}
                    widescreen={{
                        size: 'half',
                    }}
                    fullhd={{
                        size: 'half',
                    }}
                    style={{backgroundColor: '#f7f7f7', paddingTop: '100px'}}>
                    <div style={{textAlign: 'left', margin: '50px'}}>
                        {!result && <>
                            {questions.map((q, qIndex) => (
                                <div key={q.key} className={active === qIndex ? '' : 'hidden'}>
                                    <div className='question-number'>{`${t('quiz.question')}  ${qIndex + 1} / 5`}</div>
                                    <h3 className="question">{t(q.key)}</h3>
                                    {q.answers.map((a, aIndex) => (
                                        <div key={a}
                                             className={`answer ${answers[qIndex] === aIndex ? 'active-answer' : ''}`}
                                             onClick={() => setAnswer(qIndex, aIndex)}
                                        >{t(a)}</div>
                                    ))}
                                </div>
                            ))}
                            <div className={'btn-container'}>
                                {active > 0 &&
                                <Button className={'prev-btn'} onClick={() => goBack()}>{t('quiz.previous')}</Button>}
                                {active < questions.length - 1 &&
                                <Button className={'next-btn'} disabled={answers[active] === undefined}
                                        onClick={() => goNext()}>{t('quiz.next')}</Button>}
                                {active === questions.length - 1 &&
                                <Button className={'next-btn'} disabled={answers[active] === undefined}
                                        onClick={() => getResult()}>{t('quiz.send')}</Button>}
                            </div>
                        </>}

                        {result && <div style={{textAlign: 'center'}}>
                            <div className='question-number'>{t('quiz.result')}</div>
                            <div className='question'>{t(result.type)}</div>
                            <div className='result-program-name'>{t(result.name)}</div>
                            <Link className="button main-btn" style={{height: '50px', marginLeft: '0px'}}
                                  to={result.url}>{t('quiz.seeDetails')}</Link>
                        </div>}
                    </div>
                </Columns.Column>
            </Columns>
        </>);
}

export default Questionnaire;
