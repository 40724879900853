import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Hero, Columns} from "react-bulma-components";
import juniorImg from '../img/img-cursuri-juniori-min.jpg';
import juniorMin from '../img/img-cursuri-juniori-mobile-min.jpg';
import Collapsible from 'react-collapsible';
import {Link, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";

function JuniorClassPage({imgSrc, title, description, setNavClass}) {
    const {t} = useTranslation();
    let location = useLocation();
    const openItem = location.hash;

    useEffect(() => {
        setNavClass('light');
    }, [])

    return (
        <>
            <Helmet>
                <title>North Tennis | {t('classes.junior.title')}</title>
            </Helmet>
            <Hero className="snap-point is-hidden-mobile" size="fullheight"
                  style={{backgroundImage: `url(${juniorImg})`, backgroundSize: 'cover'}}>
                <Hero.Body>
                    <Columns style={{width: '100%'}}>
                        <Columns.Column
                            tablet={{
                                size: 'one-third',
                            }}
                            desktop={{
                                size: 'one-third',
                            }}
                            widescreen={{
                                size: 'one-third',
                            }}
                            fullhd={{
                                size: 'one-third',
                            }}
                        >

                        </Columns.Column>
                        <Columns.Column
                            desktop={{
                                size: 'one-third',
                            }}
                            widescreen={{
                                size: 'one-third',
                            }}
                        >
                        </Columns.Column>
                        <Columns.Column
                            tablet={{
                                size: 'half',
                            }}
                            desktop={{
                                size: 'one-third',
                            }}
                            widescreen={{
                                size: 'one-third',
                            }}
                            fullhd={{
                                size: 'one-third',
                            }}
                            className="has-text-left padded-right"
                        >
                            <div className="class-title-wrapper">
                                <h1 className="title class-title has-text-white is-spaced">{t('classes.junior.title')}</h1>
                                <Link to={'/prices'} className="price-link" >{t('classes.seePrices')}</Link>
                            </div>
                            <Collapsible trigger={t('classes.junior.novice.title')} open={openItem === '#novice'}>
                                <p>{t('classes.junior.novice.description')}</p>
                            </Collapsible>
                            <Collapsible trigger={t('classes.junior.intermediate.title')} open={openItem === '#intermediate'}>
                                <p>{t('classes.junior.intermediate.description')}</p>
                            </Collapsible>
                            <Collapsible trigger={t('classes.junior.ace.title')} open={openItem === '#ace'} >
                                <p>{t('classes.junior.ace.description')}</p>
                            </Collapsible>
                            <Collapsible trigger={t('classes.junior.physical.title')} open={openItem === '#physical'}>
                                <p>{t('classes.junior.physical.description')}</p>
                            </Collapsible>
                            <Collapsible trigger={t('classes.junior.group.title')} open={openItem === '#group'}>
                                <p>{t('classes.junior.group.description')}</p>
                            </Collapsible>
                            <Collapsible trigger={t('classes.junior.camp.title')} open={openItem === '#camp'}>
                                <p>{t('classes.junior.camp.description')}</p>
                            </Collapsible>
                            <h2 className="subtitle page-description has-text-white" dangerouslySetInnerHTML={{__html: description}}/>
                            <div className="not-sure"> {t('classes.notSure')} </div>
                            <Link className="button main-btn" to={'/quiz'} style={{height: '50px', marginLeft: '0px'}}>{t('classes.takeQuiz')}</Link>
                        </Columns.Column>
                    </Columns>
                </Hero.Body>
            </Hero>
            <div className="spacer is-hidden-tablet"/>
            <Hero className="is-hidden-tablet" size="large"
                  style={{backgroundImage: `url(${juniorMin})`, backgroundSize: 'cover'}}>
                <Hero.Body>
                    <h1 className="title class-title has-text-white is-spaced">{t('classes.junior.title')}</h1>
                </Hero.Body>
            </Hero>
            <section className="textSection is-hidden-tablet section has-text-left">
                <Collapsible trigger={t('classes.junior.novice.title')} open={openItem === '#novice'}>
                    <p>{t('classes.junior.novice.description')}</p>
                </Collapsible>
                <Collapsible trigger={t('classes.junior.intermediate.title')} open={openItem === '#intermediate'}>
                    <p>{t('classes.junior.intermediate.description')}</p>
                </Collapsible>
                <Collapsible trigger={t('classes.junior.ace.title')} open={openItem === '#ace'}>
                    <p>{t('classes.junior.ace.description')}</p>
                </Collapsible>
                <Collapsible trigger={t('classes.junior.physical.title')} open={openItem === '#physical'}>
                    <p>{t('classes.junior.physical.description')}</p>
                </Collapsible>
                <Collapsible trigger={t('classes.junior.group.title')} open={openItem === '#group'}>
                    <p>{t('classes.junior.group.description')}</p>
                </Collapsible>
                <Collapsible trigger={t('classes.junior.camp.title')} open={openItem === '#camp'}>
                    <p>{t('classes.junior.camp.description')}</p>
                </Collapsible>
                <h2 className="subtitle page-description has-text-white" dangerouslySetInnerHTML={{__html: description}}/>
                <Link to={'/prices'} className="price-link" >{t('classes.seePrices')}</Link>
                <div className="not-sure"> {t('classes.notSure')} </div>
                <Link className="button main-btn" to={'/quiz'} style={{height: '50px', marginLeft: '0px'}}>{t('classes.takeQuiz')}</Link>
            </section>
        </>
    )
}

export default JuniorClassPage;
