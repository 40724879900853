import React from 'react';
import {Columns, Hero} from "react-bulma-components";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Breadcrumbs from "./Breadcrumbs";

function Class({imgSrc, imgSrcMobile, title, description, btnText, linkTo, index}) {
    const {t} = useTranslation();
    return (<>
        <Hero id={`class-${index}`} className="snap-point is-hidden-mobile" size="fullheight"
              style={{backgroundImage: `url(${imgSrc})`, backgroundSize: 'cover'}}>
            <Hero.Body>
                <Columns style={{width: '100%'}}>
                    <Columns.Column
                        className="breadcrumb-column"
                        tablet={{
                            size: 'one-third',
                        }}
                        desktop={{
                            size: 'one-third',
                        }}
                        widescreen={{
                            size: 'one-third',
                        }}
                        fullhd={{
                            size: 'one-third',
                        }}
                    >
                        <Breadcrumbs activeIndex={index} tag="class"
                                     crumbs={[t('classes.breadcrumb'), t('classes.junior.breadcrumb'), t('classes.adult.breadcrumb'), t('classes.corporate.breadcrumb'), t('classes.school.breadcrumb')]}
                        />
                    </Columns.Column>
                    <Columns.Column
                        desktop={{
                            size: 'one-third',
                        }}
                        widescreen={{
                            size: 'one-third',
                        }}
                        fullhd={{
                            size: 'one-third',
                        }}
                    >
                    </Columns.Column>
                    <Columns.Column
                        tablet={{
                            size: 'half',
                        }}
                        desktop={{
                            size: 'one-third',
                        }}
                        widescreen={{
                            size: 'one-third',
                        }}
                        fullhd={{
                            size: 'one-third',
                        }}
                        className="has-text-left padded-right"
                    >
                        <h1 className="title page-title has-text-white is-spaced">{title}</h1>
                        <h2 className="subtitle page-description has-text-white"
                            dangerouslySetInnerHTML={{__html: description}}></h2>
                        {linkTo && <Link className="button main-btn"
                                         style={{height: '50px', marginLeft: '0px'}}
                                         to={linkTo}>{btnText}</Link>}
                    </Columns.Column>
                </Columns>
            </Hero.Body>
        </Hero>
        <div className="spacer is-hidden-tablet"/>
        <Hero id={`class-${index}`} size="large" className="is-hidden-tablet"
              style={{backgroundImage: `url(${imgSrcMobile})`, backgroundSize: 'cover'}}>
            <Hero.Body><h1 className="title page-title has-text-white is-spaced">{title}</h1></Hero.Body>
        </Hero>
        <section className="textSection is-hidden-tablet section">
            <h2 className="subtitle page-description has-text-left" dangerouslySetInnerHTML={{__html: description}}/>
            <Link className="button main-btn" style={{height: '50px', marginLeft: '0px'}} to={linkTo}>{btnText}</Link>
        </section>
    </>)
}

export default Class;
