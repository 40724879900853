import React, {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import Slide from "../components/Slide";

import adult from '../img/img-landingpage-adult-min.jpg';
import adultMin from '../img/img-landingpage-adult-mobile-min.jpg';
import junior from '../img/img-landingpage-juniori-min.jpg';
import juniorMin from '../img/img-landingpage-junior-mobile-min.jpg';
import corporate from '../img/img-landingpage-corporate-final-min.jpg';
import corporateMin from '../img/img-landingpage-junior-corporate-mobile-min.jpg';
import school from '../img/img-landingpage-scoli-min.jpg';
import schoolMin from '../img/img-landingpage-scoli-mobile-min.jpg';
import membership from '../img/membership-3.jpg';
import membershipMin from '../img/membership-3-min.jpg';
import videoAnalysis from '../img/analysis-1.jpg';
import videoAnalysisMin from '../img/analysis-1-min.jpg';

function Home({setNavClass}) {
  const {t} = useTranslation();
    const carouselEl = useRef(null);
    const slidePrev = () => carouselEl.current.slidePrev();
    const slideNext = () => carouselEl.current.slideNext();

    useEffect(() => {
        setNavClass('light');
    }, [])

    return (
        <div className="snap-point">
            <AliceCarousel dotsDisabled={false}
                           buttonsDisabled={true}
                           touchTrackingEnabled={true}
                           mouseTrackingEnabled={true}
                           ref={carouselEl}>
                <Slide imgSrc={corporate} imgSrcSmall={corporateMin} slidePrev={slidePrev} slideNext={slideNext} curr={1}
                       title={t('landing.title')} description={t('landing.description')}
                       linkTo={'/classes'} btnText={t('classes.seeClasses')}/>
                <Slide imgSrc={junior} imgSrcSmall={juniorMin} slidePrev={slidePrev} slideNext={slideNext} curr={2}
                       title={t('landing.junior.title')} description={t('landing.junior.description')}
                       linkTo={'/classes/junior'} btnText={t('classes.seeClasses')}/>
                <Slide imgSrc={adult} imgSrcSmall={adultMin} slidePrev={slidePrev} slideNext={slideNext} curr={3}
                       title={t('landing.adult.title')} description={t('landing.adult.description')} 
                       linkTo={'/classes/adults'} btnText={t('classes.seeClasses')}/>
                <Slide imgSrc={corporate} imgSrcSmall={corporateMin} slidePrev={slidePrev} slideNext={slideNext} curr={4}
                       title={t('landing.corporate.title')} description={t('landing.corporate.description')}
                       linkTo={'/classes/corporate'} btnText={t('classes.seeClasses')}/>
                <Slide imgSrc={school} imgSrcSmall={schoolMin} slidePrev={slidePrev} slideNext={slideNext} curr={5}
                       title={t('landing.school.title')} description={t('landing.school.description')}
                       linkTo={'/classes/school'} btnText={t('classes.seeClasses')}/>
                <Slide imgSrc={membership} imgSrcSmall={membershipMin} slidePrev={slidePrev} slideNext={slideNext} curr={6}
                       title={t('landing.membership.title')} description={t('landing.membership.description')}
                       linkTo={'/membership'} btnText={t('landing.membership.btn')}/>
                <Slide imgSrc={videoAnalysis} imgSrcSmall={videoAnalysisMin} slidePrev={slidePrev} slideNext={slideNext} curr={7}
                       title={t('landing.videoAnalysis.title')} description={t('landing.videoAnalysis.description')}
                       linkTo={'/video-analysis'} btnText={t('landing.videoAnalysis.btn')}/>
            </AliceCarousel>
        </div>);
}

export default Home;
